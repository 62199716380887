import React from 'react';
import PromotionsTemplate from '../../../templates/promotions';
import Leaderboard from '../../../components/Leaderboard';
import { sngChaseLeaderboardPromotionData } from '../../../data/promotions';
import config from '../../../config';

const sngChaseLeaderboardPage = ({ location, pageContext }) => (
    <PromotionsTemplate
        pageContext={{ ...pageContext, ...sngChaseLeaderboardPromotionData }}
        location={location}
        component={<Leaderboard id={sngChaseLeaderboardPromotionData.id} selectedTable="step2" leaderboardUrl={config.leaderboard.sngChaseLeaderboardChallengeUrl} catalogUrl={config.leaderboard.sngChaseLeaderboardCatalogueUrl}/>}
    /> 
);

export default sngChaseLeaderboardPage;
